.App {
  text-align: center;
  font-size: larger;
  margin: 0% 10%;
}

.Home {
  margin: 0% 15%;
}

h3, h4, h5, h6 {
  font-weight: normal;
}

h5, h6 {
  margin: 1em 0em;
}

.sabda {
  border-radius: 4px;
  padding: 2px 3px;
}

.paada {
  line-height: 2.5em;
}

.filterForm * {
  margin: 0.5em;
  padding: 2px;
}

table {
  margin: 0 auto;
  width: 30%;
}

td {
  padding: 0.5em 0;
  height: 2em;
}

.citationUses {
  cursor: pointer;
  font-weight: 800;
  margin-bottom: 5%;
}